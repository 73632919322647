import React from "react";
import CookieConsent from "react-cookie-consent";

class StyledCookieConsent extends React.Component {
  render() {
    return (
      <CookieConsent
        buttonText="Ok"
        style={{ background: "linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8)", fontWeight: 600 }}
        buttonStyle={{ background: "#1f2251", color: "white", fontSize: "13px", fontWeight: 600 }}
      >
        Um die Webseite optimal gestalten und fortlaufend verbessern zu können, verwendet wir Cookies. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies zu.
      </CookieConsent>
    );
  }
}

export default StyledCookieConsent;
