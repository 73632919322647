/*!

=========================================================
* BLK Design System React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/css/google-fonts.css";
import "assets/css/fontawesome-all.css";
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.0.0";
import "assets/demo/demo.css";

import Index from "views/pages/Index";
import Imprint from "views/pages/Imprint";
import PrivacyPolicy from "views/pages/PrivacyPolicy";
import ScrollToTop from "components/ScrollToTop";

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <Switch>
        <Route
          path="/datenschutzerklaerung"
          render={props => <PrivacyPolicy {...props} />}
        />
        <Route
          path="/impressum"
          render={props => <Imprint {...props} />}
        />
        <Route path="/" render={props => <Index {...props} />} />
      </Switch>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById("root")
);
