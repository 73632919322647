import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";
import Image from "react-image-webp";

class Plugins extends React.Component {
  render() {
    return (
      <div className="section section-basic" id="plugins">
          <Image
              alt="Waves"
              src={require('assets/img/waves.png')}
              webp={require('assets/img/webp/waves.webp')}
              className="path"
          />
        <Container>
          <Row>
            <Col lg="12">
                <h2 className="text-center">Plugins</h2>
                <Row className="row-grid">
                    <Col lg="3">
                        <div className="info plugin-info">
                            <div className="plugin-info-header">
                                <div className="plugin-icon-row">
                                    <div className="icon icon-primary">
                                        <i className="tim-icons icon-email-85" />
                                    </div>
                                    <img alt="Shopware 5" width="35" height="35" src={require("assets/img/shopware-5.png")} />
                                </div>
                                <h4 className="info-title">Bestätigungsmails für Formulare (SW 5)</h4>
                                <hr className="line-primary" />
                                <p>
                                    Dieses Plugin ermöglicht das Versenden von
                                    Bestätigungsmails an die Absender ausgewählter
                                    Formulare, denen Sie eine E-Mail-Vorlage zugewiesen haben.
                                </p>
                            </div>
                            <div className="plugin-info-footer">
                                <a href="https://docs.momocode.de/shopware-5/bestaetigungsmail-fuer-formulare" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-block">Dokumentation</a>
                                <a href="https://store.shopware.com/momo208257174672/bestaetigungsmails-fuer-formulare.html" target="_blank" rel="noopener noreferrer" className="btn btn-info btn-block">Shopware Store</a>
                            </div>
                        </div>
                    </Col>
                    <Col lg="3">
                        <div className="info plugin-info">
                            <div className="plugin-info-header">
                                <div className="plugin-icon-row">
                                    <div className="icon icon-primary">
                                        <i className="tim-icons icon-email-85" />
                                    </div>
                                    <img alt="Shopware 5" width="35" height="35" src={require("assets/img/shopware-5.png")} />
                                </div>
                                <h4 className="info-title">HTML E-Mail für Formulare (SW 5)</h4>
                                <hr className="line-primary" />
                                <p>
                                    Dieses Plugin ermöglicht das Pflegen von
                                    E-Mail-Vorlagen für Formulare, sodass dem
                                    Formular-Empfänger eine HTML E-Mail geschickt
                                    werden kann.
                                </p>
                            </div>
                            <div className="plugin-info-footer">
                                <a href="https://docs.momocode.de/shopware-5/html-e-mail-fuer-formulare" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-block">Dokumentation</a>
                                <a href="https://store.shopware.com/momo698835252763/html-e-mail-fuer-formulare.html" target="_blank" rel="noopener noreferrer" className="btn btn-info btn-block">Shopware Store</a>
                            </div>
                        </div>
                    </Col>
                    <Col lg="3">
                        <div className="info plugin-info">
                            <div className="plugin-info-header">
                                <div className="plugin-icon-row">
                                    <div className="icon icon-primary">
                                        <i className="tim-icons icon-email-85" />
                                    </div>
                                    <img alt="Shopware 6" width="35" height="35" src={require("assets/img/shopware-6.png")} />
                                </div>
                                <h4 className="info-title">Bestätigungsmails für Formulare (SW 6)</h4>
                                <hr className="line-primary" />
                                <p>
                                    Dieses Plugin ermöglicht das Versenden von
                                    Bestätigungsmails an die Absender der
                                    Kontakt-Formulare, denen Sie eine E-Mail-Vorlage zugewiesen haben.
                                </p>
                            </div>
                            <div className="plugin-info-footer">
                                <a href="https://docs.momocode.de/shopware-6/bestaetigungsmail-fuer-formulare" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-block">Dokumentation</a>
                                <a href="https://store.shopware.com/momo120498363863/bestaetigungsmails-fuer-formulare.html" target="_blank" rel="noopener noreferrer" className="btn btn-info btn-block">Shopware Store</a>
                            </div>
                        </div>
                    </Col>
                    <Col lg="3">
                        <div className="info plugin-info">
                            <div className="plugin-info-header">
                                <div className="plugin-icon-row">
                                    <div className="icon icon-primary">
                                        <i className="tim-icons icon-basket-simple" />
                                    </div>
                                    <img alt="Shopware 6" width="35" height="35" src={require("assets/img/shopware-6.png")} />
                                </div>
                                <h4 className="info-title">Shop the Look für Produktseiten (SW 6)</h4>
                                <hr className="line-primary" />
                                <p>
                                    Dieses Plugin ermöglicht das Pflegen von
                                    Looks, die in einem "Shop the Look"
                                    Element auf Produkt-Detail-Seiten
                                    angezeigt werden können.
                                </p>
                            </div>
                            <div className="plugin-info-footer">
                                <a href="https://docs.momocode.de/shopware-6/shop-the-look" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-block">Dokumentation</a>
                                <a href="https://store.shopware.com/momo937991457269/shop-the-look-fuer-produktseiten.html" target="_blank" rel="noopener noreferrer" className="btn btn-info btn-block">Shopware Store</a>
                            </div>
                        </div>
                    </Col>
                    <Col lg="3">
                        <div className="info plugin-info">
                            <div className="plugin-info-header">
                                <div className="plugin-icon-row">
                                    <div className="icon icon-primary">
                                        <i className="tim-icons icon-double-right" />
                                    </div>
                                    <img alt="Shopware 6" width="35" height="35" src={require("assets/img/shopware-6.png")} />
                                </div>
                                <h4 className="info-title">Weiterleitung auf vorherige Seite nach Login (SW 6)</h4>
                                <hr className="line-primary" />
                                <p>
                                    Dieses Plugin bietet Ihnen die Möglichkeit, den Kunden nach dem Login wieder zurück auf die vorherige Seite weiterzuleiten, anstatt auf die Startseite des Account-Bereichs.
                                </p>
                            </div>
                            <div className="plugin-info-footer">
                                <a href="https://docs.momocode.de/shopware-6/login-redirect" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-block">Dokumentation</a>
                                <a href="https://store.shopware.com/momo873271571163/weiterleitung-auf-vorherige-seite-nach-login.html" target="_blank" rel="noopener noreferrer" className="btn btn-info btn-block">Shopware Store</a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Plugins;
