import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import PageHeader from "components/PageHeader/PageHeader.jsx";
import Footer from "components/Footer/Footer.jsx";
import StyledCookieConsent from "components/StyledCookieConsent";

// sections for this page/view
import Plugins from "views/sections/Plugins.jsx";
import Certifications from "views/sections/Certifications.jsx";

class Index extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("index-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("index-page");
  }
  render() {
    return (
      <>
        <IndexNavbar />
        <div className="wrapper">
          <PageHeader />
          <div className="main">
            <Plugins />
            <Certifications />
          </div>
          <Footer />
          <StyledCookieConsent />
        </div>
      </>
    );
  }
}

export default Index;
