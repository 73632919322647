import React from "react";
import Image from 'react-image-webp';

// reactstrap components
import {
  Container,
  Row,
  Col,
  CardHeader,
  CardBody,
  Card
} from "reactstrap";

class Certifications extends React.Component {
  render() {
    return (
      <div className="section section-basic" id="plugins">
        <img alt="..." className="path" src={require("assets/img/path2.png")} />
        <Image
            alt="Path"
            src={require('assets/img/path2.png')}
            webp={require('assets/img/webp/path2.webp')}
            className="path"
        />
        <Container>
          <Row className="row-grid justify-content-between">
            <Col className="mt-lg-5" md="5">
              <Row>
                <Col className="px-2 py-2 certification-row" lg="6" sm="12">
                  <img alt="Shopware 6 Certified Developer" className="certification-img" src={require("assets/img/shopware6_certified_developer.svg")}/>
                </Col>
                <Col className="px-2 py-2 certification-row" lg="6" sm="12">
                  <img alt="Shopware 6 Certified Template Designer" className="certification-img" src={require("assets/img/shopware6_certified_template_designer.svg")}/>
                </Col>
              </Row>
              <Row>
                <Col className="px-2 py-2 certification-row" lg="6" sm="12">
                  <img alt="Shopware 5 Certified Developer" className="certification-img" src={require("assets/img/shopware_certified_developer.svg")}/>
                </Col>
                <Col className="px-2 py-2 certification-row" lg="6" sm="12">
                  <img alt="Shopware 5 Certified Developer Advanced" className="certification-img" src={require("assets/img/shopware_certified_developer_adv.svg")}/>
                </Col>
              </Row>
              <Row>
                <Col className="px-2 py-2 certification-row" lg="6" sm="12">
                  <img alt="Shopware 5 Certified Template Designer" className="certification-img" src={require("assets/img/shopware_certified_template_designer.svg")}/>
                </Col>
                <Col className="px-2 py-2 certification-row" lg="6" sm="12">
                  <img alt="Shopware 5 Certified Template Developer" className="certification-img" src={require("assets/img/shopware_certified_template_developer.svg")}/>
                </Col>
              </Row>
            </Col>
            <Col className="mt-lg-5 ml-auto" lg="4" md="6">
              <Card className="card-coin card-plain">
                <CardHeader>
                  <Image
                      alt="Moritz"
                      src={require('assets/img/moritz.jpg')}
                      webp={require('assets/img/webp/moritz.webp')}
                      className="img-center img-fluid rounded-circle"
                  />
                  <h4 className="title">Moritz Müller</h4>
                </CardHeader>
                <CardBody>
                  Als PHP-Entwickler bin ich seit 2017 auf Shopware spezialisiert und arbeite seitdem hauptberuflich in
                  einer Kölner Internet- agentur an den Shopware Projekten unserer Kunden. Nebenbei programmiere ich
                  Plugins für den Shopware Community Store und auf Anfrage auch individuell für Kunden.
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Certifications;
